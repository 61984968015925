/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.ProductInformation {
    font-size: 14px;
    line-height: 20px;
    padding: 16px;
    background-color: white;

    &-Wrapper {
        display: flex;
        flex-direction: column;
        padding: 5%;
        grid-row-gap: 50px;
        row-gap: 50px;
        background-color: #FFFFFF;
        @include mobile {
            padding: 0;
        }
    }

    &-ExpandableContentContent {
        line-height: 20px;
        text-align: justify;

        + div {
            margin-block-end: 12px;
        }

        &_isContentExpanded {
            margin-block-end: 16px;
        }
    }

    &-ExpandableContentHeading {
        @include desktop {
            display: none;
        }
    }

    &-Placeholder {
        margin-block-start: 24px;

        @include mobile {
            margin-block-start: 28px;
        }

        span {
            display: block;
            margin-block-end: 12px;

            @include mobile {
                margin-block-end: 14px;
            }
        }
    }

    &-Content {
        @include desktop {
            margin-block-end: 12px;
        }
    }

    &-Description {
        word-break: break-word;

        @include description-tags {
            font-size: 14px;
            line-height: 20px;
        }

        span {
            font-weight: 600;
            font-size: 20px;
            line-height: 23px;
            color: #000;
        }

        li {
            &::before {
                position: relative;
                margin-inline-start: 10px;
                margin-inline-end: -25px;
                inset-block-start: -1px;
            }
        }

        @include default-list;

        @include mobile {
            line-height: 20px;
        }
    }
}
