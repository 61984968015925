/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
 /* stylelint-disable */

:root {
    --product-review-item-border: #d8d8d8;
}

.ProductReviewItem {
    display: flex;
    flex-direction: column;
    word-break: break-word;
    border-bottom: 1px solid var(--product-review-item-border);
    text-align: start;
    padding-block-end: 16px;
    row-gap: 16px;

    @include mobile {
        row-gap: 8px;
    }

    &::before {
        content: none;
    }

    &-Row0 {
        display: flex;
        justify-content: space-between;
    }

    &-Row1 {
        display: flex;
        align-items: center;
        column-gap: 8px;

        &-Rating {
            display: flex;

            .ProductReviewRating {
                margin-left: 0!important;
                column-gap: 2px;
                svg {
                    width: 20px;
                    height: 20px;
                    margin-left: 0px;

                    @include mobile {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }

        &-Name {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #000000;

            @include mobile {
                font-size: 12px;
                line-height: 14px;
            }
        }

        &-Circle {
            width: 4px;
            height: 4px;
            background-color: #000000;
            border-radius: 50%;

            @include mobile {
                display: none;
            }
        }

        &-Date {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #818284;

            @include mobile {
                font-size: 10px;
                line-height: 12px;
            }
        }
    }

    &-Row2{
        display: flex;
        align-items: center;
        column-gap: 12px;

        &-ReviewTitle {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #000000;

            @include mobile {
                font-size: 14px;
                line-height: 16px;
            }
        }
    
        &-VerifiedPurchaser {
            display: flex;
            align-items: center;
            column-gap: 6px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #818284;

            svg {
                width: 24px;
                height: 24px;
            }

            @include mobile {
                font-size: 10px;
                line-height: 12px;

                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    &-Content {
        grid-area: content;
    }

    &-RatingSummary {
        grid-area: ratings;
        line-height: 1;

        @include desktop {
            min-width: 180px;
        }

        @include mobile {
            margin-block-end: 14px;
            width: 100%;
        }
    }

    &-RatingSummaryItem {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-block-end: 16px;

        @include mobile {
            margin-block-end: 7px;
        }

        &:last-of-type {
            margin-block-end: 0;
        }

        .ProductReviewRating {
            --star-size: 20px;

            margin-block: 0 2px;
            margin-inline: 12px 0;

            @include mobile {
                margin-block: 0 3px;
                margin-inline: 14px 0;
            }
        }
    }

    &-ReviewContent {
        flex: 1 0 0;
        margin-inline-end: 48px;

        @include mobile {
            flex: 0 0 auto;
            margin-inline-start: 0;
            width: 100%;
            order: 3;
        }

        @include tablet {
            flex: auto;
            order: 15;
            margin-block-start: 18px;
        }

        .TextPlaceholder {
            display: block;
        }
    }

    &-ReviewAuthor {
        margin-block: 15px 0;
        font-size: 12px;

        @include mobile {
            line-height: 20px;
        }
    }

    &-ReviewDetails {
        margin-block-end: 0;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #818284;

        @include mobile {
            font-size: 12px;
            line-height: 14px;
        }

        .TextPlaceholder {
            margin-block-start: 6px;
            line-height: 1.1;

            @include mobile {
                margin-block-start: 7px;
            }

            &::after {
                display: block;
            }
        }
    }

    .ProductReviewRating-Counter {
        display: none;
    }

    &-RatingItem {
        margin-block: auto;

        @include mobile {
            line-height: 20px;
        }
    }
}
