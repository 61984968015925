/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
 /* stylelint-disable */
.ProductPage {
* { -ms-overflow-style: none; scrollbar-width: none; }
*::-webkit-scrollbar {display: none;}
    .ProductGallery {
        margin: 0;
        .ProductGallery-SliderWrapper {
            display: flex;

            .ProductGallery-Slider {
                width: 100%;
            }
        }
        .ProductGallery-Additional {
            display: flex;
            justify-content: center;
        }
        .Slider-Arrow {
            position: relative;
            &_isNext {
                right: 0px;
            }
            &_isPrev {
                left: 0px;
            }
        }
    }

    @include tablet {
        padding-block-start: 0;
        margin-block-start: 0;
    }

    @include mobile {
        // padding-block-end: 133px;
        padding-inline: 16px;

        .ProductGallery-Additional {
            display: flex;
        }
    }

    &-TopBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-TopDiscount {
        font-family: 'Lato';
        font-style: italic;
        font-weight: 900;
        font-size: 12px;
        line-height: 14px;        
        color: #DE4D47;
        @include desktop {
            display: none;
        }
    }

    &-ShareProduct {
        position: absolute;
        right: 40px;
        top: 40px;
        z-index: 98;
        svg {
            width: 32px;
            height: 32px;
        }

        @include mobile {
            position: relative;
            right: 0px;
            top: 0px;
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    &-Wrapper {
        display: grid;
        padding-inline: 8%;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 24px;
        grid-template-rows: min-content min-content;
        padding-block-start: 24px;
        background-color: white;

        @include desktop {
            grid-column-gap: 48px;
            grid-template-areas: '. actions';
            padding-block-start: 40px;
            padding-block-end: 72px;

            .ProductActions {
                grid-area: actions;
            }
        }

        @include mobile {
            grid-template-columns: 100%;
            padding: 16px;
            padding-block-end: 42px;
        }

        > .RelatedProducts {
            display: none;

            @include desktop {
                display: block;
            }
        }
    }

    .ProductAttributeValue{
        &-Color::before, &-Image-Overlay::before {
            box-shadow: inset 0 0 0 1px black;
        }
        &-Color::after {
            display: none;
        }
    }
    .ProductConfigurableAttributes{
        &-SwatchList {
            flex-direction: row;
            gap: 8px;
        }
        &-Title {
            margin-top: 0;
        }
        &-OptionLabel {
            display: none;
        }
        &-SelectedOptionLabel {
            padding-left: 0;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: #000000;
            opacity: 1;
        }
    }

    &-AdditionalSections {
        display: grid;
        grid-template-columns: auto 302px;
        gap: 24px;
        @include mobile {
            display: block;
        }
        &-Left {
            margin-top: 44px
        }
        &-Right {
            //display: none;
            margin-top: 44px;
            &-Sticky {
                position: sticky;
                top: calc(var(--header-total-height) + 20px);
                display: flex;
                flex-direction: column;
                gap: 24px;
                @include desktop {
                    padding-bottom: 40px;
                }
            }
        }
    }
    &-Faq {
        background-color: white;
        padding: 47px 16px;
        @include mobile {
            padding-block-end: 0;
        }
        &-title {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 80px;

            @include mobile {
                margin-bottom: 29px;
            }
    
            span {
                background-image: url('https://www.zaynmyza.com/media/new_zm/image_28.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-position: center;
                line-height: 53px;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                text-align: center;
                color: #161616;
    
                @include mobile {
                    font-size: 24px;
                }
    
            }
    
        }
    
        &-Container {
            display: flex;
            align-items: flex-start;
            column-gap: 24px;
    
            @include mobile {
                flex-direction: column;
            }
        }
    
        &-Image{
            width: 40%;
            text-align: end;
            @include mobile {
                width: 100%;
            }
            img {
                width: 100%;
            }
        }
    
        &-Content {
            width: calc(50% - 24px);
    
            @include mobile {
                width: 100%;
                margin-top: 34px;
            }
        }
    }
}
