/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
 /* stylelint-disable */

.ProductReviewList {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    @include mobile {
    }

    &-Title {
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        margin-block-start: 36px;

        @include mobile {
            font-size: 28px;
            margin-block-start: 42px;
        }
    }

    &-Pagination {
        display: flex;
        column-gap: 8px;

        &-Button {
            width: 40px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #D5D5D5;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #8B8B8B;

            svg {
                stroke: black;
            }

            &_isActive {
                border: 1px solid black;
                background: #000000;
                color: white;

                svg {
                    stroke: white;
                }
            }

            &_isDisabled {
                opacity: 0.5;
            }
        }
    }
}
