/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
.HomeProductsSlider {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    * {
        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
        /* for Firefox */
    }
    *::-webkit-scrollbar {
        display: none;
        /* for Chrome, Safari, and Opera */
    }
    @include mobile {
        max-height: 446px;
        padding-block: 30px;
    }
    @include desktop {
        justify-content: space-between;
        height: auto;
        padding-block: 50px;
    }
    
    &-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        @include mobile {
            margin-bottom: 20px;
        }
        span {
            background-image: url('https://www.zaynmyza.com/media/new_zm/image_28.png');
            background-repeat: no-repeat;
            background-size: cover;
            line-height: 43px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            text-align: center;
            padding-inline: 20px;
            color: #161616;
            @include desktop {
                font-size: 32px;
            }
            @include mobile {
                font-size: 24px;
                font-weight: 600;
            }
        }
    }
    .slick {
        &-track {
            display: flex;
            column-gap: 10px;
        }
        &-list {
            // max-height: 350px;
            // max-height: 380px;
        }
        &-slide{
            width: 312px;
            // padding-inline: 10px;
            @include mobile {
                width: 224px;
                padding-inline: 0px;
            }
            &:first-child {
                margin-left: auto;
            }
            &:last-child {
                margin-right: auto;
            }
        }
        &-cloned:first-child {
            display: none;
        }
        &-arrow {
            width: 48px;
            height: 48px;
            z-index: 4;
            @include mobile {
                width: 42px;
                height: 42px;
            }
        }
        &-prev {
            display: none !important;
            left: 2%;
            opacity: 0.5;
            background: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                height: auto;
                transform: rotate(180deg);
            }
            &::before {
                display: none !important;
            }
        }
        &-next {
            right: 2%;
            opacity: 0.5;
            background: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                height: auto;
            }
            &::before {
                display: none !important;
            }
        }
    }
    &-products {
        margin-inline: auto;
        //max-width: var(--content-wrapper-width);
        max-width: 95%;
        margin-inline: auto 0%;
        // height: 470px;
        width: 100%;
        // @include mobile {
        //     height: 342px;
        // }
        .slick-slider, .slick-initialized {
            width: 100%;
            // height: 440px;
            overflow: hidden;
        }
        .ProductCard {
            width: 100%;
            max-width: 312px;
            min-width: 270px;
            height: 414px;
            margin-inline: 5px;
            @include mobile {
                height: 305px;
                max-width: 224px;
                min-width: 180px;
                width: 100%;
            }
        }
        ul {
            display: flex;
            flex-direction: row;
            overflow-x: scroll;
            // column-gap: 8px;
            li {
                margin-left: 10px;
                // flex: 0 0 302px;
                // height: 422px;
            
                @include mobile {
                    margin-left: 0;
                    // flex: 0 0 44%;
                    // height: 283px;
                }
            }
        }
    }
}