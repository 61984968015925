/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.GroupedProductsItem {
    display: grid;
    align-items: start;
    padding-inline-start: 0;
    margin-block-end: 12px;
    grid-template-columns: 72px 3fr 1fr;
    grid-column-gap: 18px;

    @include mobile {
        margin-block-end: 14px;
        grid-template-columns: 84px 3fr 1fr;
        grid-column-gap: 14px;
    }

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 0;
    }

    &:not(:last-of-type) {
        margin-block-end: 24px;
    }

    &-OutOfStock {
        text-align: end;
        font-size: 14px;
        font-weight: bold;

        @include mobile {
            white-space: nowrap;
        }
    }

    .Field {
        &_type_numberWithControls {
            margin-block-start: 0;
        }

        &-ErrorMessages {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }

    &-Image {
        height: auto;

        &::after {
            content: '';
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: 0;
            inset-inline-start: 0;
            inset-block-end: 0;
            background-color: var(--product-card-picture-mask-color);
            mix-blend-mode: multiply;
            pointer-events: none;
        }
    }

    &-Title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow: hidden;

        p {
            margin-block-end: 12px;
            margin-block-start: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 90%;

            @include mobile {
                margin-block-end: 14px;
            }
        }
    }

    &-Quantity .Field {
        margin: 0;
    }

    &-Quantity {
        input {
            @include mobile {
                min-width: 36px;
            }
        }
    }

    .ProductPrice {
        &-Price {
            font-size: 18px;
        }

        del {
            display: block;
        }
    }

    .TierPrices {
        grid-column: 2 / -1;
    }
}
