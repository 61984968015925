/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
.ProductLinks {

    * {
        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
        /* for Firefox */
    }
    *::-webkit-scrollbar {
        display: none;
    /* for Chrome, Safari, and Opera */
    }
    width: 100%;

    @include mobile {
        margin-block-start: 14px;
    }

    @include desktop {
        grid-column: 1 / span 2;
    }

    &-Wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 41px;
        padding-block-start: 0;
        padding-block-end: 61px;
        margin-inline: auto;

        @include mobile {
            padding-inline: 16px 0;
            padding-block: 30px;
        }
    }

    &-List {
        display: flex;
        overflow: scroll;
        grid-gap: 12px;
        // grid-template-columns: 1fr 1fr;

        @include mobile {
            grid-gap: 14px;
        }

        @include desktop {
            grid-template-columns: repeat(6, 1fr);
            grid-gap: 24px;
        }

        @include narrow-desktop {
            grid-template-columns: repeat(4, 1fr);
        }

        @include tablet {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &-Title {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            background-image: url('https://www.zaynmyza.com/media/new_zm/image_28.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            line-height: 53px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            text-align: center;
            padding-inline: 20px;
            color: #161616;

            @include mobile {
                //line-height: 30px;
                font-size: 20px;
            }
        }
    }

    .Image_imageStatus_1 {
        mix-blend-mode: multiply;
    }
}
