/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

 /* stylelint-disable */


@media only screen and (min-width: 811px) {
    .Product-Description-Wrap {
       display: flex;
       flex-direction: column;
       padding: 5%;
       row-gap: 50px;
       background-color: #FFFFFF;
       .Product-Description-Row {
           display: flex;
           justify-content: space-between;
           align-items: center;
           .Product-Description-Image{
               width: 37%;
           }
           .Product-Description-Content{
               display: flex;
               flex-direction: column;
            //    row-gap: 16px;
               width: 54%;
               align-items: flex-start;
               &-Title {
                   font-style: normal;
                   font-weight: 600;
                   font-size: 20px;
                   line-height: 23px;
                   text-align: left;
                   color: #000000;
                   margin-bottom: 20px;
               }
               &-SubTitle {
                   font-style: normal;
                   font-weight: 600;
                   font-size: 16px;
                   line-height: 18px;
                   text-align: left;
                   color: #000000;
                   margin-bottom: 14px;
               }
               p {
                    text-align: left;
                   font-style: normal;
                   font-weight: 400;
                   font-size: 14px;
                   line-height: 18px;
                   color: #000000;
                   margin-bottom: 12px;
               }
           }
       }
   }
}
@media only screen and (max-width: 811px) {
    .Product-Description-Wrap {
       display: flex;
       flex-direction: column;
       padding: 5%;
       row-gap: 16px;
       background-color: #FFFFFF;
       .Product-Description-Row {
           display: flex;
           flex-direction: column;
           row-gap: 16px;
           .Product-Description-Image{
               width: 100%;
           }
           .Product-Description-Content{
               display: flex;
               flex-direction: column;
            //    row-gap: 16px;
               width: 100%;
               align-items: flex-start;
               &-Title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 18px;
                text-align: left;
                    color: #000000;
                    margin-bottom: 20px;
               }
               &-SubTitle {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 18px;
                text-align: left;
                    color: #000000;
                    margin-bottom: 16px;
               }
               p {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-align: left;
                color: #000000;
                margin-bottom: 10px;
               }
           }
       }
   }
}

// HeroIngredients
@media only screen and (min-width: 811px) {
    .Product-HeroIngredients-Wrap{
        padding: 50px;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        margin-top: 40px;
        row-gap: 50px;
        .Product-HeroIngredients-Title{
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                font-family: 'Poppins';
                background-image: url('https://www.zaynmyza.com/media/new_zm/image_28.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                padding-inline: 30px;
                background-position: center;
                line-height: 53px;
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                text-align: center;
                color: #161616;
            }
        }
        .Product-HeroIngredients-Row{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .Product-HeroIngredients-Col{
                display: flex;
                flex-direction: column;
                width: ( 90% / 3);
                .Product-HeroIngredients-Image{
                    padding-inline: 40px;
                    padding-bottom: 20px;
                    
                }
                .Product-HeroIngredients-Content{
                    display: flex;
                    flex-direction: column;
                    row-gap: 16px;
                    span {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 23px;
                        text-align: center;
                        color: #161616;
                    }
                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        text-align: center;
                        color: #000000;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 811px) {
    .Product-HeroIngredients-Wrap{
        padding: 24px 16px;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        row-gap: 30px;
        margin-top: 16px;
        .Product-HeroIngredients-Title{
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                font-family: 'Poppins';
                background-image: url('https://www.zaynmyza.com/media/new_zm/image_28.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
padding-inline: 30px;
                background-position: center;
                line-height: 53px;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                text-align: center;
                color: #161616;
            }
        }
        .Product-HeroIngredients-Row{
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            .Product-HeroIngredients-Col{
                display: flex;
                flex-direction: row;
                .Product-HeroIngredients-Image{
                    padding-inline-end: 16px;
                    width: 40%;
                    
                }
                .Product-HeroIngredients-Content{
                    display: flex;
                    flex-direction: column;
                    row-gap: 8px;
                    width: 60%;
                    span {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 18px;
                        text-align: left;
                        color: #161616;
                    }
                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        text-align: left;
                        color: #000000;
                    }
                }
            }
        }
    }
}

// Benefits
@media only screen and (min-width: 811px) {
.Product-Benefits-Wrap{
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    row-gap: 50px;
    margin-top: 40px;
    .Product-Benefits-Title{
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            font-family: 'Poppins';
            background-image: url('https://www.zaynmyza.com/media/new_zm/image_28.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
padding-inline: 30px;
            background-position: center;
            line-height: 53px;
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            text-align: center;
            color: #161616;
        }
    }
    .Product-Benefits-Row{
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        hr {
            margin: 0;
        }
        .Product-Benefits-Content{
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            span{
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 23px;
                text-align: left;
                color: #000000;
            }
            p{
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #383838;
                text-align: left;
                width: 82%;
            }
        }
    }
}
}
@media only screen and (max-width: 811px) {
.Product-Benefits-Wrap{
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    row-gap: 30px;
    margin-top: 16px;
    .Product-Benefits-Title{
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            font-family: 'Poppins';
            background-image: url('https://www.zaynmyza.com/media/new_zm/image_28.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            padding-inline: 30px;
            background-position: center;
            line-height: 53px;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            text-align: center;
            color: #161616;
        }
    }
    .Product-Benefits-Row{
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        hr {
            margin: 0;
        }
        .Product-Benefits-Content{
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            span{
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 18px;
                text-align: left;
                color: #000000;
            }
            p{
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                line-height: 14px;
                color: #383838;
                text-align: left;
            }
        }
    }
}
}

// HowToUse
@media only screen and (min-width: 811px) {
    .Product-HowToUse-Wrap{
        padding: 40px 24px;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        row-gap: 50px;
        margin-top: 40px;
        .Product-HowToUse-Title{
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                font-family: 'Poppins';
                background-image: url('https://www.zaynmyza.com/media/new_zm/image_28.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
padding-inline: 30px;
                background-position: center;
                line-height: 53px;
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                text-align: center;
                color: #161616;
            }
        }
        .Product-HowToUse-Row{
            display: flex;
            justify-content: space-between;
            .Product-HowToUse-Left{
                width: 48%;
            }
            .Product-HowToUse-Right{
                display: flex;
                flex-direction: column;
                width: 48%;
                row-gap: 24px;
                .Product-HowToUse-Container{
                    display: flex;
                    justify-content: space-between;
                    .Product-HowToUse-Image{
                        width: 21%;                    
                    }
                    .Product-HowToUse-Content{
                        display: flex;
                        flex-direction: column;
                        width: 73%;
                        row-gap: 16px;
                        span{
                            font-style: normal;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 23px;
                            text-align: left;
                            color: #000000;
                            
                        }
                        p{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            text-align: center;
                            color: #818284;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 811px) {
    .Product-HowToUse-Wrap{
        padding: 23px 16px;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        row-gap: 30px;
        margin-top: 16px;
        .Product-HowToUse-Title{
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                font-family: 'Poppins';
                background-image: url('https://www.zaynmyza.com/media/new_zm/image_28.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
padding-inline: 30px;
                background-position: center;
                line-height: 53px;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                text-align: center;
                color: #161616;
            }
        }
        .Product-HowToUse-Row{
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            .Product-HowToUse-Left{
                width: 100%;
            }
            .Product-HowToUse-Right{
                display: flex;
                flex-direction: column;
                width: 100%;
                row-gap: 24px;
                .Product-HowToUse-Container{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .Product-HowToUse-Image{
                        width: 22%;                    
                    }
                    .Product-HowToUse-Content{
                        display: flex;
                        flex-direction: column;
                        width: 73%;
                        row-gap: 4px;
                        span{
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 18px;
                            text-align: left;
                            color: #000000;
                            
                        }
                        p{
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            text-align: center;
                            color: #818284;
                            text-align: left;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

.ProductActions {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    @include mobile {
        padding-bottom: 5px;
    }

    &-Attributes {
        padding: 0;
    }

    &-Brand {
        font-weight: bold;
        opacity: .48;
        font-size: 12px;
        line-height: 16px;
    }

    &-Title {
        font-family: 'Poppins';
        text-transform: none;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: 0.05em;
        color: var(--color-black);
        
        @include mobile {
            font-size: 18px;
        }
    }

    &-UseOf {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: #000000;
        
        @include mobile {
            font-size: 14px;
            line-height: 20px;
        }
    }

    &-ShortDescription {
        margin-left: 16px;
        @include mobile {
            margin-left: 6px;
        }
        @include description-tags {
            line-height: 20px;
        }
        span {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #000000;

            @include mobile {
                font-size: 12px;
                line-height: 20px;
            }
        }

        li {
            list-style-type: disc;
            list-style-position: outside;
            &:last-child {
                margin-bottom: 0 !important;
            }
            &::before {
                display: none;
            }
        }
    }

    &-ShortButton {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #DE4C47;
        cursor: pointer;

        @include mobile {
            font-size: 12px;
            line-height: 20px;
        }        
    }

    &-Row{
        display: flex;
        flex-direction: row;
        column-gap: 8px;
        @include mobile {
            column-gap: 4px;                
        }
    }

    &-Column{
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        @include mobile {
            row-gap: 4px;                
        }
    }

    &-SuitableFor {
        display: flex;
        column-gap: 40px;
        @include mobile {
            flex-direction: column;
            row-gap: 16px;
        }
        &-Title{
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            padding: 5px 14px;
            width: fit-content;
            border-radius: 21px;
            background-color: #4274b9;
        }
        &-Container {
            display: flex;
            gap: 20px;
            @include mobile {
                flex-direction: column;
                gap: 0;
            }
        }
        &-Text{
            display: flex;
            align-items: center;
            column-gap: 4px;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: #3376c0;
            text-align: center;
            border-radius: 5px;
            svg, img {
                width: 22px;
                height: 22px;
                path {
                    fill: #3376c0;
                }
            }
            @include mobile {
                margin-top: 5px;
                font-size: 12px;
                line-height: 14px;
            }
        }
    }

    &-NotAdvisibleFor {
        display: flex;
        column-gap: 40px;
        @include mobile {
            flex-direction: column;
            row-gap: 16px;
        }
        &-Title{
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            padding: 5px 14px;
            width: fit-content;
            border-radius: 21px;
            background-color: #ed2755;
        }
        &-Container {
            display: flex;
            gap: 8;
            @include mobile {
                flex-direction: column;
            }
        }
        &-Text{
            display: flex;
            align-items: center;
            column-gap: 4px;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: #ff3750;
            text-align: center;
            border-radius: 5px;
            svg, img {
                width: 22px;
                height: 22px;
                path {
                    fill: #ff3750;
                }
            }
            @include mobile {
                margin-top: 5px;
                font-size: 12px;
                line-height: 14px;
            }
        }
    }

    &-Stock {
        font-weight: bold;
        order: 0;

        @include desktop {
            margin-inline-end: 24px;
        }

        @include mobile {
            font-style: normal;
            font-size: 12px;
            line-height: 22px;
            margin-block-start: 8px;
        }
    }

    &-Sku {
        order: 1;

        @include mobile {
            font-weight: normal;
            font-size: 12px;
            margin-block-start: 4px;
            text-align: end;
        }

        & + .ProductActions-Sku {
            margin-inline-start: 1ex;
        }
    }

    &-Review {
        order: 2;
        font-weight: 700;
        cursor: pointer;
        inset-block-end: -2px;
        font-size: 14px;

        @include mobile {
            inset-block-end: 0;
        }

        &Text {
            color: var(--primary-base-color);

            &:hover {
                color: var(--primary-dark-color);
            }

            &_isNotSafariOrIos {
                @include mobile {
                    vertical-align: -webkit-baseline-middle;
                }
            }
        }
    }

    &-Section {
        &_type {
            &_sku {
                display: flex;

                @include mobile {
                    flex: 1;
                    align-items: flex-end;
                    flex-direction: column;
                }

                .TextPlaceholder {
                    line-height: 30px;
                }
            }

            &_alerts {
                margin-block-start: 10px;
                margin-block-end: 10px;
            }
        }
    }

    &-Schema {
        min-width: 150px;
        @include mobile {
            width: 100%;
            margin-inline-end: 0;
        }
    }

    &-SchemaUrl {
        display: none;
    }

    &-PriceWrapper {
        .ProductPrice {
            // border-bottom: 1px solid rgba(129, 130, 132, 0.2);
            // padding-bottom: 16px;
            // margin-bottom: 16px;
            @include mobile {
                align-items: flex-end;
                flex-direction: column;
                row-gap: 6px;
                &-Discount {
                    font-size: 14px;
                    line-height: 14px;
                    background: #de4d47;
                    padding: 3px 4px 3px 3px;
                    color: white;
                }
            }
        }
        .TextPlaceholder {
            line-height: 30px;
        }
    }

    &-ActionButtons {
        display: flex;
    }

    &-ActionsWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block-end: 12px;

        &:nth-child(2) {
            @include mobile {
                align-items: flex-start;
            }
        }

        &:nth-child(3) {
            @include mobile {
                margin-block-end: 22px;
            }
        }

        @include desktop {
            margin-block: 18px;
        }

        .ProductCompareButton {
            margin-inline-start: auto;
        }

        &_isWithoutPriceTotal .ProductActions-Section_type_sku {
            @include mobile {
                float: end;
            }
        }
    }

    &-Block {
        display: flex;
        column-gap: 40px;
        &-Wrap {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
        }
        &-Title {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: #000000;
        }
        .Field-Wrapper_type_numberWithControls{
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            height: 45px;
        }
        .Field {
            margin-top: 0;
        }
    }

    &-Qty {
        &.Field {
            margin-block: 0;

            @include desktop {
                // margin-inline-end: 32px;
                width: 100%;
                padding: 8px 10px;
                column-gap: 13px;

                button {
                    border: none;
                    height: min-content;
                    width: min-content;

                    svg {
                        fill: black;
                    }
                }

                input {
                    min-width: 36px;
                }
            }

            @include mobile {
                // margin-inline-end: 12px;
                width: 100%;
                padding: 17px;
                justify-content: space-between;

                button {
                    border: none;
                    height: min-content;
                    width: min-content;

                    svg {
                        fill: black;
                    }
                }

                input {
                    min-width: 36px;
                }
            }
        }

        button {
            &:not([disabled]) {
                cursor: pointer;
            }

            &:disabled {
                cursor: default;
            }

            &:active {
                border-color: var(--primary-dark-color);
            }

            @include hoverable {
                &:hover {
                    border-color: var(--primary-dark-color);
                }
            }
        }
    }

    &-AddToCartFixed {
        @include mobile-bottom-wrapper {
            padding: 12px 16px;
            inset-block-end: 16px;
            display: flex;
        }

        @include mobile {
            height: 56px;
            // inset-block-end: var(--footer-nav-height);
            inset-block-end: 0;
            padding: 0;
            border: none;
        }

        .ProductWishlistButton {
            margin-inline: 18px 4px;
            margin-block: auto;

            @include mobile {
                display: none;
            }
        }

        .Field {
            &-Wrapper {
                &_type {
                    &_numberWithControls {
                        @include mobile {
                            display: flex;
                            width: 45%;
                        }
                    }
                }
            }
        }
    }

    &-TryOn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 40px;
        column-gap: 8px;
        background: #818284;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;

        svg {
            stroke: #FFFFFF;
            width: 24px;
            height: 24px;
        }

        @include mobile {
            position: fixed;
            bottom: 72px;
            right: 10px;
            z-index: 1;
            box-shadow: 0px 4px 4px #00000040;
            border-radius: 70px;
            padding: 12px;
            width: fit-content;
            background-color: #383838;
        }
    }

    &-AddToCartWrapper {
        align-items: center;
        display: flex;
        margin-block-start: 24px;
        column-gap: 24px;

        .ProductActions-AddToCart {
            //margin-inline-end: 16px;
            max-width: 180px;
            max-height: 40px;
            background: #DE4D47;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
            svg {
                fill: none;
                stroke: white;
            }

            @include ultra-narrow-desktop {
                //order: 10;
                //margin-block-start: 24px;
            }
        }

        .ProductCompareButton,
        .ProductWishlistButton {
            margin-inline: 16px;
        }

        @include ultra-narrow-desktop {
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &_isPrerendered {
            @include mobile {
                inset-block-end: 0;
            }
        }

        .Field-ErrorMessages {
            position: absolute;
        }
    }

    &-SABanner{
        display: flex;
        padding: 8px 20px;
        // background: #FFEAF0;
        border-bottom: 1px solid #DE4D47;
        width: fit-content;
        span {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
        }
        a {
            font-style: normal;
            font-weight: 800;
            font-size: 15px;
            line-height: 16px;
            color: #DE4D47;
        }
    }

    &-AttributesWrapper {
        @include mobile {
            // margin-block-end: 16px;
        }

        &.animate {
            animation: shake 820ms .5ms cubic-bezier(.36, .07, .19, .97) both;
        }
    }

    .Halal {
        display: flex;
        justify-content: space-between;
        overflow: scroll;
        white-space: nowrap;
        padding-top: 30px;
        @include mobile {
            padding-top: 10px;
            column-gap: 2px;
        }

        &-Container {
            display: flex;
            align-items: center;
            column-gap: 16px;
    
            svg {
                width: 48px;
                height: 48px;
            }
            @include mobile {
                column-gap: 2px;
            }
        }
        &-Name {
            display: flex;
            flex-direction: column;
            row-gap: 4px;
            @include mobile {
                text-align: center;
            }
        }
        &-Title {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-align: center;            
            color: #000000;
            @include mobile {
                font-size: 12px;
                line-height: 15px;
            }
        }
        &-Divider {
            border-left: 1px solid #D9D9D9;
        }
    }

    &-GroupedItems {
        margin-block-start: 24px;
    }

    &-Reviews {
        // margin-block-end: 10px;
        display: flex;

        @include mobile {
            position: absolute;
            margin-block-end: 0;
        }

        .ProductReviewRating {
            margin-inline-end: 24px;
        }
    }

    .ProductConfigurableAttributes-Expandable {
        margin-block-start: 24px;
    }

    .ExpandableContent:first-of-type {
        border-block-start: 0;
    }
    .PdpTimer {
        .SaleTimer-Heading {
            margin-bottom: 0;
        }
        .SaleTimer-Countdown {
            gap: 0;
        }
    }
}

.mobile-spacing {
    row-gap: 20px;
}