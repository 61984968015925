/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/* stylelint-disable */ 
.ProductAlert {
  order: 3;
  &-Heading {
    margin: 20px 0 15px;
    color: #282c3f;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }
  &-Form {
    display: flex;
    align-items: center;
    .Field {
      margin-top: 0;
    }
  }
  &-Input {
    margin: 0;
  }
  &-Preorder{
    order: 11;
    margin-bottom: 15px;
    &Button{
      margin-block-start: 20px;
    }
    &Popup{
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 999;

    }
    &Overlay{
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0,0,0,0.5);
      width: 100%;
      height: 100%;
    }
    &Content{
      background: #ffffff;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      max-width: 600px;
      min-height: 400px;
      border-radius: 7px;
      transform: translate(-50%, -50%);
      padding: 30px;
      @include mobile{
        width: 90%;
      }
    }
    &Close{
      position: absolute;
      right: 7px;
      top: 7px;
      font-size: 43px;
      line-height: 24px;
      height: 24px;
    }
    &Input{
      display: flex;
      flex-direction: column;
      margin-block-end: 20px;
      label{
        font-size: 14px;
        font-weight: 600;
        margin-block-end: 4px;
        span{
          color: #dedede;
          &.required{
            color: #cd0000;
          }
        }
      }
      textarea{
        resize: none;
        width: 100%;
      }
    }
    &Submit{
      .Button{
        width: 100%;
      }
    }
    &Error{
      color: #cd0000;
      margin-block: 10px;
    }
  }
  &-ErrorMessage{
    color: #cd0000;
    margin-block: 10px;
  }
  &-SuccessMessage{
    color: #64BC26;
    margin-block: 10px;
  }
}
