/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.ProductRecommendation {
    &-Wrapper {
        @include mobile {
            margin-top: 93px;
        }
    }
    &-Heading {
        margin-top: 50px;
        @include mobile {
            margin-top: 28px;
            font-size: 20px;
        }
    }
    &-ProductList {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-block: 50px;
        gap: 20px;
        @include mobile {
            grid-template-columns: repeat(2, 1fr);
            margin-block: 30px;
        }
    }
}
