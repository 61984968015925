/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
 /* stylelint-disable */

:root {
    --price-color: #{$black};
    --price-with-discount-color: #b10d0d;
    --price-discount-color: #0A0903;
}

.ProductPrice {
    color: var(--price-color);
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    line-height: 24px;
    margin-block-end: 0;
    margin-block-start: 0;
    display: flex;
    align-items: center;
    column-gap: 16px;

    @include desktop {
        // min-height: 40px;
    }

    @include mobile {
        font-size: 18px;
        column-gap: 8px;
        margin: 0 !important;
        padding: 0 !important;
    }

    &-Price {
        text-decoration: none;
        display: inline-flex;
        align-items: baseline;
        flex-wrap: wrap;
    }

    del {
        // font-size: 14px;
        // font-weight: 500;
        // opacity: .48;
        // display: inline-block;
        // margin-inline-end: 12px;

        @include mobile {
            // margin-block-start: 6px;
        }
    }

    &-HighPrice {
        // color: var(--price-color);
        // line-height: 18px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        text-decoration-line: line-through;
        color: #818284;

        @include mobile {
            font-size: 16px;
            line-height: 20px;
            // padding-block: 3px;
            // padding-right: 6px;
        }
    }

    &-PriceValue {
        // margin-block-start: 6px;
        // line-height: 24px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        color: #000000;
        font-size: 18px;
        line-height: 18px;
        margin-top: 6px;

        @include desktop {
            // line-height: 28px;
            margin-top: 0;
            font-size: 20px;
            line-height: 23px;
        }

        @include mobile {
            // margin-block-start: 4px;
        }
    }

    &, &-BundleFrom, &-BundleTo {
        &_hasDiscount {
            .ProductPrice {
                &-PriceBadge {
                    margin-block-start: 5px;
                    margin-block-end: -5px;
                }
            }
        }
    }

    &-Placeholder {
        &::after {
            content: 'aaaaa';
        }
    }

    &-SubPrice {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-block-start: 4px;

        @include desktop {
            line-height: 16px;
        }
    }

    &-PriceBadge {
        font-size: 14px;
        font-weight: 400;
        margin-inline-end: 4px;
        white-space: nowrap;

        @include desktop {
            line-height: 20px;
        }
    }

    &-Discount {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
        text-transform: uppercase;
        color: #de4d47;
    }
}
