/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
/* stylelint-disable */ 
:root {
    --product-card-picture-mask-color: #f5f5f5;
    --product-card-color-border-color: #efefef;
    --product-card-text-border-color: #979797;
    --product-card-picture-label-background: rgba(255, 255, 255, .7);
    --product-card-reviews-background: rgba(243, 243, 243, .55);
    --product-card-wishlist-button-background: rgba(243, 243, 243, .78);
    --product-card-wishlist-button-color: #d8d5d5;
    --product-card-compare-button-background: var(--product-card-wishlist-button-background);
    --product-card-compare-button-color: var(--product-card-wishlist-button-color);
    --product-card-name-max-rows: 2;
    --product-card-brand-line-height: 1.2em;
    --product-card-color-size: 32px;
    --product-card-background: var(--color-white);
    --option-margin-block: 0 12px;
    --option-margin-inline: 0 12px;
}

.ProductCard {
    height: fit-content;
    padding-inline-start: 0;
    min-width: 0;
    display: flex;
    align-items: stretch;


    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 7px;

        @include mobile {
            margin-block-end: 8px;
        }
    }

    .ProductConfigurableAttributes {
        &-Title {
            display: none;
        }
    }

    &-Attributes {
        padding: 0;
    }

    &-ConfigurationNotice {
        opacity: 0.3;
        font-weight: normal;
        margin-block-start: -10px;
        margin-block-end: 10px;
    }

    &-Content {
        background: var(--product-card-background);
        padding-block: 10px 16px;
        display: flex;
        flex-direction: column;

        @include desktop {
            padding-inline: 15px;
        }

        &_layout_list {
            justify-content: space-between;
            margin-inline-start: 20px;
            max-width: 325px;

            .ProductCard {
                &-ActionWrapper {
                    align-items: center;
                    display: flex;
                    visibility: hidden;

                    &_loaded {
                        visibility: visible;
                    }

                    button {
                        margin-inline-end: 10px;
                    }
                }

                &-ProductActions {
                    flex-direction: row;
                    position: static;

                    > div {
                        margin-block-end: 0;
                    }

                    .ProductCompareButton-Button {
                        height: var(--compare-button-size);
                        width: var(--compare-button-size);
                    }
                }

                &-OutOfStock {
                    p {
                        margin-inline-end: 10px;
                    }
                }

                &-PriceWrapper {
                    flex-grow: unset;
                    margin-block-end: 16px;
                }

                &-WishListButton {
                    height: var(--wishlist-heart-size);
                    width: var(--wishlist-heart-size);
                }
            }
        }
    }

    &-Name,
    &-Brand {
        width: 100%;
        max-width: 100%;
    }

    &-Name {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 5px 0;
        padding-block-end: 2px;

        @include desktop {
            line-height: 20px;
        }

        .TextPlaceholder {
            margin-block-end: 0;
        }
    }

    &-Brand {
        font-weight: 400;
        margin-block-end: 4px;
        opacity: .5;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-height: var(--product-card-brand-line-height);
        line-height: var(--product-card-brand-line-height);
        font-size: 12px;

        @include desktop {
            line-height: 16px;
        }
    }

    &-BrandAttributeValue {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    &-Picture {
        padding-block-end: 120%;
    }

    &-PictureMissing {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        inset-block-end: 0;
        margin: auto;
        inset-block-start: 0;
    }

    &-LinkInnerWrapper {
        width: 100%;
        height: 100%;
        background-color: #FFFFFF;

        &:hover {
            @include desktop {
                box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .1),
                    0 6px 16px rgba(0, 0, 0, .1),
                    0 9px 28px 8px rgba(0, 0, 0, .1);
                z-index: 4;
            }
        }

        &_loaded:hover {
            @include desktop {
                .ProductCard-VisibleOnHover {
                    background: var(--product-card-background);
                    width: inherit;
                    opacity: 1;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, .1),
                        0 6px 16px rgba(0, 0, 0, .1),
                        0 9px 28px 8px rgba(0, 0, 0, .1);
                    z-index: -1;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    flex-grow: 1;
                    padding-block-start: 0;
                    padding-block-end: 10px;
                    padding-inline: 15px;
                    position: absolute;
                    inset-block-start: 100%;
                }
            }
        }
    }

    &-FigureReview {
        background: var(--product-card-background);
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include desktop {
            padding-block: 10px;
            padding-inline: 15px;
        }
    }

    &-Reviews {
        --product-rating-size: 16px;
        --product-rating-background: #ffff;

        width: 100%;
        border-radius: 10px;
        letter-spacing: normal;

        @include mobile {
            --product-rating-size: 14px;

            width: 100%;
            border-radius: 0;
            height: 18px;
        }

        @include desktop {
            height: 25px;
        }
    }

    &-ConfigurableOptions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        min-height: calc(var(--product-card-color-size) + 5px);
    }

    &-Color,
    &-Image {
        width: var(--product-card-color-size);
        height: var(--product-card-color-size);
        border-radius: 50%;
        overflow: hidden;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        border: 1px solid var(--product-card-color-border-color);
    }

    &-String {
        border: 1px solid var(--product-card-text-border-color);
        color: var(--product-card-text-border-color);
        display: inline-block;
        text-align: center;
        padding: 0 6px;
        margin: 0;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        line-height: 15px;
        min-width: 20px;
    }

    &-PriceWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @include mobile {
            // padding-top: 8px;
        }
    }

    &-Price {
        width: 100%;

        @include desktop {
            font-size: 24px;
        }
    }

    &-PriceBadge {
        color: var(--secondary-dark-color);
        font-size: 10px;
        margin-block-end: 0;
    }

    .ProductPrice-PriceBadge {
        font-size: 12px;
    }

    .ProductPrice-BundleTo {
        @include mobile {
            margin-block-start: 6px;
        }
    }

    &-Figure {
        flex-grow: 1;
    }

    &-Link {
        color: inherit;
        display: flex;
        flex-direction: column;
        width: 100%;

        &:hover,
        &:focus {
            color: initial;
            text-decoration: none;
        }
    }

    &-ProductActions {
        display: flex;
        margin-inline-start: 7px;

        .ProductWishlistButton {
            margin-inline-end: 10px;
        }
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    &-Footer {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    &-OutOfStock {
        align-items: center;
        display: flex;
        height: 48px;

        p {
            margin: 0;
        }
    }

    &-VisibleOnHover {
        flex-direction: column;
        justify-content: flex-end;
        flex-grow: 1;
        padding-block-start: 0;
        padding-block-end: 10px;
        padding-inline: 15px;
        opacity: 0;
        position: absolute;
        inset-block-start: 100%;
        display: none;
    }

    &_siblingsHaveBrands {
        .ProductCard-Brand {
            min-height: var(--product-card-brand-line-height);
        }
    }

    &_siblingsHaveTierPrice,
    &_siblingsHavePriceBadge {
        .ProductCard-PriceWrapper {
            min-height: 40px;
        }
    }

    &_siblingsHaveConfigurableOptions {
        .ProductCard-ConfigurableOptions {
            min-height: 20px;
        }
    }
}

.ProductCard {
    width: 100%;
    max-width: 312px;
    min-width: 270px;
    height: 414px;
    @include mobile {
        height: 305px;
        max-width: 224px;
        min-width: 150px;
        width: 100%;
        justify-self: center;
    }
    &-label {
        z-index: 1;
        letter-spacing: 1px;
        text-transform: uppercase;
        position: absolute;
        top: 4px;
        font-family: 'Lato';
        font-style: italic;
        font-weight: 900;
        @include desktop {
            font-size: 12px;
            line-height: 14px;
        }
        @include mobile {
            font-size: 10px;
            line-height: 12px;
        }
        &-left {
            left: 10px;
            color: #2D37D0;
        }
        &-right {
            font-style: italic;
            color: #DE4D47;
            right: 10px;
        }
    }
    &-FigureReview {
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 0;
        height: 276px;

        @include mobile {
            height: 183px;
        }
    }

    &-Figure {
        width: 100%;
        height: 276px;

        img {
            position: relative;
            width: 100%;
            height: 276px;
            object-position: 50% 50%;
            object-fit: contain;
            padding-block: 20px 10px;
            @include mobile {
                height: 183px;
            }
        }

        @include mobile {
            height: 183px;
        }
    }
    &-Content {
        padding-block: 0;
        padding-inline: 10px;
        @include mobile {
            padding-bottom: 17px;
        }
    }
    &-Name {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #000000;
        height: 34px;
        @include mobile {
            font-size: 14px;
            line-height: 16px;
            margin: 0;
            height: 37px;
        }
    }
    &-UseOf{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #8B8B8B;
        // margin-bottom: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 14px;
        margin-block-end: 4px;
    }

    &-ProductSize {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #8B8B8B;
        margin-block-start: 4px;
        height: 15px;
        overflow: hidden;
    }
    &-Rating {
        display: flex;
        margin-block: 8px;
        height: 22px;
        &-card {
            display: flex;
            background-color: #F0F0F0;
            border-radius: 20%;
            width: 52px;
            height: 22px;
            padding: 4px 8px;
            column-gap: 5px;
            justify-content: center;        
            span {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #000000;
            }
            svg {
                position: absolute;
                right: 2px;
                min-width: 12px;
            }
            @include mobile {
                // padding-top: 8px;
            }
        }
        &-review {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #161616;
            align-self: center;
            padding-left: 12px;
        }
    }
    .ProductPrice {
        height: 20px;
        @include mobile {
            height: 16px;
        }
        &-Price {
            order: 2;
        }
        &-HighPrice {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-decoration-line: line-through;
            color: #8B8B8B;
            order: 1;
            @include mobile {
                font-size: 14px;
                line-height: 16px;
            }
        }
        &-PriceValue {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            @include mobile {
                font-size: 14px;
                line-height: 16px;
            }
        }
        &-Discount {
            display: none;
        }
    }
    &-Footer{
        width: 100%;
        bottom: 0;
        overflow: hidden;
        position: absolute;
        z-index: 21;
    }
    &-Footer > *{
        margin: 0;
    }
    &-AddToCart{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 10px;
        gap: 8px;
        background: #DE4D47;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        text-transform: none;
        border: none;
        span {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 900;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
        }
        @include mobile {
            height: 38px !important;
            span {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }

    &-PreviewOptions{
        position: absolute;
        top: 0;
        left: 0;
        background: #ffffff;
        width: 100%;
        height: 100%;
        max-height: 308px;
        padding: 8px;
        z-index: 21;
        display: flex;
        flex-direction: column;
        &Header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }
        &Heading{
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 0;
        }
        .ProductConfigurableAttributes-SwatchList {
            flex-direction: row;
        }
        .ProductActions-AttributesWrapper{
            flex: 1;
            .ProductCard-Attributes{
                height: 100%;
                .ProductConfigurableAttributes-Title{
                    display: block;
                    margin-top: 0;
                    margin-bottom: 10px;
                }
                .ProductAttributeValue-Color{
                    border-radius: 30px;
                    width: 30px;
                    height: 30px;
                    margin-right: 10px;
                    margin-bottom: 0px;
                    &::before{
                        left: 0;
                        top: 0;
                        width: 30px;
                        height: 30px;
                    }
                    &::after{
                        border-left: 2px solid var(--option-check-mark-background);
                        height: calc(30px / 5);
                        top: 34%;
                        width: calc(30px / 1.9);
                        transform: rotate(-45deg);
                        border-bottom: 2px solid var(--option-check-mark-background);
                        left: calc(30px / 4);
                        top: calc(30px / 4);
                    }
                }
            }
        }
    }
    &-BundleOptions {
        display: none;
    }
}
