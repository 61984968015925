/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
 /* stylelint-disable */
.ProductReviews {
    background-color: white;
    padding: 16px;
    margin-block: 40px;
    @include desktop {
        z-index: 1;
    }
    @include mobile {
        margin-block: 16px;
    }

    &-Title {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            background-image: url('https://www.zaynmyza.com/media/new_zm/image_28.png');
            background-repeat: no-repeat;
            background-size: contain;
            padding-inline: 40px;
            background-position: center;
            line-height: 53px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            text-align: center;
            color: #161616;

            @include desktop {
                font-size: 32px;
            }
        }
    }

    &-Wrapper {
        padding: 0;
        flex-direction: column;
        display: flex;
        row-gap: 24px;

        @include desktop {
            padding: 36px 24px;
            row-gap: 40px;
        }
    }

    &-Content {
        display: flex;
        flex-direction: column;
        row-gap: 37px;

        @include mobile {
            row-gap: 24px;
        }
    }

    &-Left {
        width: 273px;
        display: flex;
        align-items: flex-start;
        padding: 16px;
        padding-top: 0;

        @include desktop {
            justify-content: space-between;
            border-right: 1px solid black;
        }

        @include mobile {
            width: 100%;
            column-gap: 16px;
            padding-bottom: 0;
            border-bottom: 1px solid #D9D9D9;

        }

        &-Col1 {
            display: flex;
            column-gap: 16px;
            align-items: center;
            span {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: 36px;
                display: flex;
                align-items: center;
                text-transform: capitalize;
                color: #000000;
            }

            @include mobile {
                column-gap: 8px;
                span {
                    font-size: 24px;
                    line-height: 27px;
                }
            }
        }
        &-Col2 {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            span {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
            }

            p {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                color: #9C9C9C;
            }

            @include mobile {
                row-gap: 4px;
                span {
                    font-size: 14px;
                    line-height: 16px;
                }
                p {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }
    }

    &-Right {
        width: 278px;
        display: flex;
        flex-direction: column;
        padding-inline-start: 16px;
        row-gap: 26px;
        span {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #9C9C9C;
        }

        @include mobile {
            width:  100%;
            padding-inline-start: 0;
            row-gap: 16px;

            span {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }

    &-Summary {
        align-self: stretch;
        display: flex;
        flex-wrap: wrap;
        padding-block-end: 27px;
        border-bottom: 1px solid #D9D9D9;

        @include mobile {
            flex-direction: column;
            row-gap: 8px;
            padding-block-end: 0px;
            border-bottom: none;
        }
    }

    &-SummaryRating {
        --star-size: 27px;

        .ProductReviewRating-Counter {
            display: none;
        }
    }

    &-ExpandableContentHeading,
    &-ExpandableContentButton {
        @include desktop {
            display: none;
        }
    }

    &-ExpandableContentContent {
        @include desktop {
            margin-block-start: 0;
        }

        &_isContentExpanded {
            padding-block-start: 0;
        }

        .ProductReviewRating {
            align-items: center;
            margin-block-start: -5px;
        }
    }

    & &-Button {
        //position: absolute;
        //inset-inline-end: 0;
        //inset-block-start: 0;
        //inset-block-end: 0;
        //margin: auto;
        width: 170px;
        height: 40px;
        background: #DE4D47;
        border: 1px solid #DE4D47;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        text-transform: capitalize;

        @include mobile {
            position: relative;
            width: 100%;
            //margin-block-start: 18px;
            line-height: 20px;
        }
    }

    &-Container {
        display: flex;
        flex-direction: column;
        row-gap: 34px;
        &-Title{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: #818284;
        }

        @include mobile {
            row-gap: 24px;
            span {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }

    &-SummaryDetails {
        display: inline-block;
        margin-inline-start: 12px;
        margin-block: auto;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        font-style: normal;

        @include mobile {
            margin-inline-start: 14px;
            line-height: 24px;
            font-size: 18px;
        }

        span {
            &::before {
                content: ' / ';
            }
        }
    }
}
